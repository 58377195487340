import { defineStore } from 'pinia';
import { useCandidatesActions } from './actions';
import { useCandidateState } from './state';
import { extractStore } from '~/stores/extractStore';
import { useCandidatesGetters } from '~/stores/candidates/getters';

export const useCandidates = defineStore('candidates', () => {
  return {
    ...extractStore(useCandidateState()),
    ...extractStore(useCandidatesGetters()),
    ...extractStore(useCandidatesActions())
  };
});
