import type { ResponseCounts } from '@emotioncod/cm-definitions/lib/ServiceLayer/Admin/Candidates/GET';
import type {
  Candidate,
  CandidateItems,
  CandidateStats,
  SkillsCategories
} from '@emotioncod/cm-definitions/lib/Candidate';
import type { ClientPagination } from '~/types/bo-types';

export interface CandidateState {
  pagination: ClientPagination;
  counts: ResponseCounts;
  candidates: Candidate[];
  candidate: Candidate | null;
  cachedResult: number;
  /**
   * This is a client side search.
   * It's used to get all candidates list from cache and filter them from UI.
   * TODO: remove this when we have a server side search.
   */
  isSearched: boolean;
  skillsCategories: SkillsCategories[];
  candidateItems: CandidateItems | null;
  candidateStats: CandidateStats | null;
}

const InitialState: CandidateState = {
  counts: {
    candidates: 1245,
    candidatesWithApplication: 1,
    candidatesWithMatch: 8,
    matches: 50,
    opportunities: 1
  },
  candidates: [],
  candidate: null,
  pagination: {
    currentPage: 0,
    total: 1,
    perPage: 30
  },
  cachedResult: 0,
  isSearched: false,
  skillsCategories: [],
  candidateItems: null,
  candidateStats: null
};

export const useCandidateState = defineStore('candidates.state', () => {
  const counts = ref<CandidateState['counts']>(InitialState.counts);
  const candidates = ref<CandidateState['candidates']>(InitialState.candidates);
  const pagination = ref<CandidateState['pagination']>(InitialState.pagination);
  const cachedResult = ref<CandidateState['cachedResult']>(
    InitialState.cachedResult
  );
  const isSearched = ref<CandidateState['isSearched']>(InitialState.isSearched);
  const candidate = ref<CandidateState['candidate']>(InitialState.candidate);
  const skillsCategories = ref<CandidateState['skillsCategories']>();
  const candidateItems = ref<CandidateState['candidateItems']>(
    InitialState.candidateItems
  );
  const candidateStats = ref<CandidateState['candidateStats']>(
    InitialState.candidateStats
  );

  return {
    counts,
    candidates,
    pagination,
    cachedResult,
    isSearched,
    candidate,
    skillsCategories,
    candidateItems,
    candidateStats
  };
});
