import type { ResponseBody as ResponseBodyCandidate } from '@emotioncod/cm-definitions/lib/ServiceLayer/Admin/Candidate/PUT';
import type { ResponseBody as ResponseBodyDelete } from '@emotioncod/cm-definitions/lib/ServiceLayer/Admin/Candidate/DELETE';
import type { ResponseBody } from '@emotioncod/cm-definitions/lib/ServiceLayer/Admin/Candidate/GET';
import type { ResponseBody as ResponseBodyCandidateItem } from '@emotioncod/cm-definitions/lib/ServiceLayer/Admin/Candidate/PUT/Item';
import type { ResponseBody as ResponseBodyComment } from '@emotioncod/cm-definitions/lib/ServiceLayer/Admin/Candidate/POST/Comment';
import type { ResponseBody as ResponseBodyCandidateStats } from '@emotioncod/cm-definitions/lib/ServiceLayer/Admin/Candidate/Stats/GET';

import type {
  Candidate,
  CandidateEvents,
  CandidateItemToUpdate
} from '@emotioncod/cm-definitions/lib/Candidate';
import { GET_CANDIDATE_REST } from '~/constants';
import { execute } from '~/services/utils';

/**
 * @name getCandidate
 * @description Details of a Candidate
 */
const getCandidate = async (
  uid: string
): Promise<ResponseBody['data'] | undefined> => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBody['data']>(
    apiClient.get(GET_CANDIDATE_REST + `/${uid}`)
  );
};

/**
 * @name getCandidateJobsStats
 * @description Stats of a Candidate
 */
const getCandidateStats = async (
  uid: string
): Promise<ResponseBodyCandidateStats['data'] | undefined> => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyCandidateStats['data']>(
    apiClient.get(GET_CANDIDATE_REST + `/${uid}` + `/stats`)
  );
};

const updateCandidate = async (
  userId: string,
  candidate: Partial<Candidate>
): Promise<ResponseBodyCandidate['data'] | undefined> => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyCandidate['data']>(
    apiClient.put(GET_CANDIDATE_REST + `/${userId}`, candidate)
  );
};

const updateCandidateItem = async (
  userId: string,
  item: CandidateItemToUpdate
): Promise<ResponseBodyCandidateItem['data'] | undefined> => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyCandidateItem['data']>(
    apiClient.put(GET_CANDIDATE_REST + `/${userId}/item`, item)
  );
};

const deleteCandidate = async (
  id: string
): Promise<ResponseBodyDelete['data'] | undefined> => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyDelete['data']>(
    apiClient.delete(GET_CANDIDATE_REST + `/${id}`)
  );
};

const postComment = async (
  id: string,
  comment: Partial<CandidateEvents>
): Promise<ResponseBodyComment['data'] | undefined> => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyComment['data']>(
    apiClient.post(GET_CANDIDATE_REST + `/${id}/comment`, { comment })
  );
};

export default {
  getCandidate,
  updateCandidate,
  updateCandidateItem,
  deleteCandidate,
  postComment,
  getCandidateStats
};
